const assets = {
    images: {
        background: require('./images/backgrounds/background.jpg'),
        tutorial_background: require('./images/backgrounds/tutorial_background.png'),
        bg_score: require('./images/bg-score.png'),
        big_shadow: require('./images/big-shadow.png'),
        btn_play: require('./images/btn-play.png'),
        btn_sfx: require('./images/btn-sfx.png'),
        donut: require('./images/donut.png'),
        donuts_logo: require('./images/donuts_logo.png'),
        text_timeup: require('./images/text-timeup.png'),
        gem_01: require('./images/game/gem-01.png'),
        gem_02: require('./images/game/gem-02.png'),
        gem_03: require('./images/game/gem-03.png'),
        gem_04: require('./images/game/gem-04.png'),
        gem_05: require('./images/game/gem-05.png'),
        gem_06: require('./images/game/gem-06.png'),
        gem_07: require('./images/game/gem-07.png'),
        gem_08: require('./images/game/gem-08.png'),
        gem_09: require('./images/game/gem-09.png'),
        gem_10: require('./images/game/gem-10.png'),
        gem_11: require('./images/game/gem-11.png'),
        gem_12: require('./images/game/gem-12.png'),
        shadow: require('./images/game/shadow.png'),
        hand: require('./images/game/hand.png'),
        particle_ex1: require('./images/particles/particle_ex1.png'),
        particle_ex2: require('./images/particles/particle_ex2.png'),
        particle_ex3: require('./images/particles/particle_ex3.png'),
        particle_1: require('./images/particles/particle-1.png'),
        particle_2: require('./images/particles/particle-2.png'),
        particle_3: require('./images/particles/particle-3.png'),
        particle_4: require('./images/particles/particle-4.png'),
        particle_5: require('./images/particles/particle-5.png'),
    },
    audio: {
        background: require('./audio/background.mp3'),
        kill: require('./audio/kill.mp3'),
        select_1: require('./audio/select-1.mp3'),
        select_2: require('./audio/select-2.mp3'),
        select_3: require('./audio/select-3.mp3'),
        select_4: require('./audio/select-4.mp3'),
        select_5: require('./audio/select-5.mp3'),
        select_6: require('./audio/select-6.mp3'),
        select_7: require('./audio/select-7.mp3'),
        select_8: require('./audio/select-8.mp3'),
        select_9: require('./audio/select-9.mp3'),
    }
}

export default assets